const fr = {
  menu: {
    about: "À propos",
    admin: "Admin",
    confirm: "Attention: Êtes-vous sûr de vouloir revenir au début? Toutes les données que vous avez saisies seront perdues.",
    language: "Choisissez votre langue",
    logout: "Logout",
    voice: {
      headerName: "Voix",
      confirm: "Attention : Êtes-vous sûr de vouloir commencer par les tâches vocales ? ",
    },
    questionnaires: {
      headerName: "Questionnaires",
      confirm: "Attention : Êtes-vous sûr de vouloir commencer par les questionnaires ? ",
    },
    footer: {
      information: "Informations",
    },
  },
  project: {
    clickToStart: "Commencer",
    goToNextStep: "Continuer",
    patientIDFootnote: "ID patient:",
    consent: {
      title: "Consentement",
      accept: "Je reconnais avoir lu ce document, j'en accepte les termes et je ne m'oppose donc pas à la Recherche.",
      date: "Date:",
      continue: "Continuer",
    },
    usertype: {
      newUser: "C’est ma première participation",
      existingUser: "J’ai déjà participé et je veux participer à nouveau",
    },
    newUser: {
      title: "Merci d'indiquer votre adresse email",
      explication:
        "Aucun message ne sera envoyé à l’adresse email que vous nous indiquerez. Elle ne sera utilisée que pour retrouver" +
        " vos données lors d’une prochaine participation à l’étude ou si vous souhaitez exercer vos droits.",
      //email: "Pour le recevoir, veuillez indiquer votre adresse email ci-après.",
      //userid: "Code",
      //privacy: "NB : votre adresse email ne sera pas conservée, elle ne sera utilisée que pour vous envoyer ce code.",
      //send: "Envoyer code",
      continue: "Continuer",
    },
    existingUser: {
      title: "Merci de vous authentifier en indiquant votre adresse email",
      explication:
        "Aucun message ne sera envoyé à l’adresse email que vous nous avez indiquée. Elle n'est utilisée que " +
        "pour retrouver vos données historiques et nous permettre d'appliquer vos souhaits éventuels quant à l'exercice de vos droits.",
      continue: "Confirmer",
      //forgot: "Je ne retrouve pas mon code",
    },
    phoneNumber: {
      title: "Merci d'indiquer votre numéro de portable",
      explication:
        "Vous allez recevoir un SMS vous permettant de compléter cet échantillon dans la salle d'attente. Vous recevrez " +
        "un SMS similaire dans 1 semaine pour fournir un nouvel échantillon depuis votre domicile, et ainsi jusqu'à la fin de l'étude.",
      continue: "Confirmer",
    },
    recording: {
      title: "Tâches vocales",
      instructions: {
        title: "Instructions",
        first: "Trouvez un environnement calme",
        second:
          "Essayez de maintenir une distance constante entre votre bouche et votre appareil d'enregistrement pendant les tâches vocales",
        third: "Suivez les instructions données pour les tâches vocales",
        continue: "Continuer",
      },
      tasks: "Tâches de l'étape :",
    },
    questionnaire: {
      title: "Questionnaire",
      description: "Répondez aux questions suivantes",
    },
    done: {
      questionnairesNext: {
        title: "Cette partie est terminée, merci !",
        continue: "Continuer avec les questionnaires",
      },
      recordingsNext: {
        title: "Cette partie est terminée, merci ! Veuillez maintenant mettre le casque sur votre tête.",
        continue: "Continuer avec les tâches vocales",
      },
      information: "Pour toute question sur l’étude, un menu 'Informations' est disponible en bas de chaque page dès la page suivante.",
    },
    final: {
      title: "L’étude est terminée, merci pour votre participation!",
    },
  },
  audioplayer: {
    pause: "Pause",
    stop: "Stop",
    listen: "Écouter",
    record: "Enregistrer",
    recordAgain: "Enregistrer à nouveau",
    instruction: "You can go to the next step once you have finished the task",
    rerecord: "If you like, you can re-record the audio or go to the next step",
  },
  admin: {
    title: "Questionnaire administration",
    select: "Sélectionnez un ID patient pour remplir le questionnaire",
    table: {
      idColumnName: "ID",
      questionnairesColumnName: "Quest.",
      recordingsColumnName: "Enregistr.",
      doctorsColumnName: "Médecin",
      completeButton: "compléter",
    },
  },
  mh6: {
    sdm: {
      title: "Souvenir",
      phrase1:
        "Nous allons vous demander de vous rappeler et de raconter oralement trois souvenirs particuliers de votre vie" +
        " que nous appelons des souvenirs définissants le soi. Un souvenir définissant le soi est un souvenir personnel" +
        " qui a les propriétés suivantes :\n",
      list: {
        1: "  1. Il date d'au moins un an.",
        2:
          "  2. Il s'agit d'un souvenir de votre vie dont vous vous êtes souvenu très clairement et qui vous semble" +
          " toujours important, même lorsque vous y pensez.",
        3:
          "  3. Il s'agit d'un souvenir concernant un thème, une question ou un conflit important et durable de votre" +
          " vie. C'est un souvenir qui aide à expliquer qui vous êtes en tant qu'individu et qui pourrait être le" +
          " souvenir que vous raconteriez à quelqu'un d'autre si vous vouliez que cette personne vous comprenne de" +
          " manière approfondie. ",
        4: "  4. C'est un souvenir lié à d'autres souvenirs similaires qui partagent le même thème ou la même préoccupation.",
        5:
          "  5. Il peut s'agir d'un souvenir qui est positif ou négatif, ou les deux. Le seul aspect important est " +
          "qu'il est associé à des émotions intenses..",
        6:
          "  6. Il s'agit d'un souvenir auquel vous avez pensé de nombreuses fois. Il doit vous être familier, comme" +
          " une image que vous avez étudiée ou une chanson (joyeuse ou triste) que vous avez apprise par cœur.",
      },
      phrase2:
        "\nAfin de comprendre ce qu’est un souvenir définissant le soi, imaginez que vous avez rencontré une personne" +
        " que vous aimez bien et vous allez vous promener ensemble. Vous essayez de faire comprendre à l’autre qui " +
        "vous êtes réellement, votre « vrai moi ». Bien que ce que vous pouvez dire ne donne pas forcément une image " +
        "exacte de vous-même, soyez le plus honnête possible. Durant la conversation, vous décrivez plusieurs souvenirs" +
        " dont vous estimez qu’ils expriment bien comment vous en êtes arrivé à devenir la personne que vous êtes " +
        "maintenant. Ce sont exactement ces souvenirs qui constituent les souvenirs définissant le soi. Décrivez chaque" +
        " souvenir avec suffisamment de détails pour qu’ils puissent aider votre ami imaginaire à voir et à ressentir" +
        " ce que vous avez vécu.\n",
      phrase3:
        "Ensuite pour chaque souvenir, nous vous demandons de lui donner un bref titre (légende) et de remplir une " +
        "série de questions sur ce que ce souvenir suscite chez vous. ",
      phrase4:
        "Avant de commencer les enregistrements, assurez-vous que vous êtes dans un environnement calme, sans bruits" +
        " dérangeants (ex. télévision, fenêtres ou portes ouvertes) et que votre microphone fonctionne. Lorsque vous" +
        " commencez les enregistrements, veuillez accepter la demande d'activation du microphone.",
    },
    fp: {
      title: "Projection Futur",
      phrase1:
        "Nous allons vous demander d’imaginer et de raconter oralement trois événements futurs personnels " +
        "particulièrement importants pour vous. \nCes évènements futurs doivent répondre aux critères suivants : ",
      list: {
        1: "  1. La distance temporelle de l’évènement futur par rapport au présent doit être d'au moins un an.",
        2: "  2. L’évènement futur doit être un évènement important que vous anticipez et vous vous représentez de manière claire. ",
        3: "  3. Il doit vous permettre d'expliquer qui vous êtes en tant que personne.",
        4:
          "  4. Il doit s'agir d'un événement lié à un thème, une question, un conflit ou une préoccupation important" +
          " et durable de votre vie et lié à d'autres événements partageant le même thème. ",
        5: "  5. Il peut s'agir d'un événement positif ou négatif ; le seul aspect important est qu'il génère des émotions intenses. ",
        6: "  6. Il doit s'agir d'un événement auquel vous avez pensé à plusieurs reprises.",
      },
    },
    cs: {
      title: "Schéma de vie culturel",
      phrase1:
        "Pour cette prochaine tâche, nous vous demandons d'imaginer un nourrisson tout à fait ordinaire (choisissez" +
        " un garçon ou une fille selon votre propre genre). Il ne peut s'agir d'un enfant précis que vous connaissez," +
        " mais plutôt d'un enfant « typique » de votre culture dont le parcours de vie est tout à fait ordinaire. " +
        "Votre tâche consiste à écrire les sept événements les plus importants qui, selon vous, ont de fortes chances " +
        "de se produire dans la vie de ce nouveau-né « typique ». Inscrivez les événements dans l'ordre où ils vous " +
        "viennent à l'esprit. Donnez à chaque événement un titre court qui précise son contenu.",
    },
    recordingInfo: {
      title: "Tâches vocales",
      text:
        "Nous allons vous demander d'effectuer plusieurs tâches vocales qui nous permettront de mieux traiter votre " +
        "voix pour les parties précédentes de l'expérience. Vérifiez que votre microphone est allumé ou autorisez son " +
        "utilisation. Suivez les instructions qui vous sont données dans les pages suivantes.",
    },
  },
}

export default fr
