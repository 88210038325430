import useLocales from "../../hooks/useLocales"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import usePatient from "../../hooks/usePatient"
import projects from "../../@projects"
import { Box, Button, Container, Typography } from "@mui/material"
import Page from "../../components/Page"
import { styled } from "@mui/material/styles"
import React, { useEffect, useState } from "react"
import { MedicalQuestion } from "../../@types/medicalQuestion"
import { MixedQuestionRecordingStructure } from "../../@types/mixedQuestionRecording"
import { CustomStateLocationCountRepetitionsProps, GeneralAnswers } from "../../@types/patient"
import useTimeUser from "../../hooks/useTimeUser"
import RenderMedicalQuestion from "../../components/questionnaire/RenderMedicalQuestion"

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 880,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(1, 0),
}))

const CulturalStoryQuestions = () => {
  const { translate, currentLang } = useLocales()
  const navigate = useNavigate()
  const { id } = useParams()
  const { patient, savePatientLocally, updateQuestionnaire } = usePatient()
  const [filteredQuestions, setFilteredQuestions] = useState<MedicalQuestion[]>([])
  const [currentProgress, setCurrentProgress] = useState(0)
  const [csTask, setcsTask] = useState<MixedQuestionRecordingStructure>()

  const [answersForm, setAnswersForm] = useState<GeneralAnswers[]>([])
  const [questionnaireCompleted, setQuestionnaireCompleted] = useState(false)
  const { timeUser, handleStartTimeUser, handleResetTimeUser } = useTimeUser()
  const { state } = useLocation() as CustomStateLocationCountRepetitionsProps
  const { currentRepetition } = state

  const project = projects.find((project) => project.projectID === id)

  if (!project) {
    return <Navigate replace to="/" />
  }

  useEffect(() => {
    handleStartTimeUser()
  }, [])

  useEffect(() => {
    if (project && project.culturalStory) {
      const csTask = project.culturalStory?.filter((task) => task.type === "cs")[0]
      setcsTask(csTask)
    }
  }, [project])

  // We filter out all the tasks by the language selected, and we store them in filteredRecordingTasks
  useEffect(() => {
    if (project && project.culturalStory) {
      const filteredQuestionnairesTasksByLang = project.culturalStory
        ?.filter((task) => task.type === "cs")
        .flatMap((task) => task.task.questionnaires)
        .filter((questionnaires) => questionnaires.lang === currentLang.value)
        .map((question) => ({
          ...question,
          id: question.id + `_${currentRepetition}`,
        }))

      if (filteredQuestionnairesTasksByLang) {
        setFilteredQuestions(filteredQuestionnairesTasksByLang)
      }
    }
  }, [currentLang])

  // Navigate to new page when patient structure changes
  useEffect(() => {
    const isQuestionsForCurrentRepetitionAnswered =
      patient.questionsAnswered.filter((questionnaires) => questionnaires.name === csTask?.type).length > 0

    if (isQuestionsForCurrentRepetitionAnswered) {
      if (currentRepetition === csTask?.repetitions) {
        navigate(location.pathname.replace(`culturalstoryquestions`, `mhsixrecordingintro`))
      } else {
        navigate(location.pathname.replace(`culturalstory`, `culturalstory`), {
          state: { currentRepetition: currentRepetition + 1 },
        })
      }
    }
  }, [patient])

  // initialise questionnaires states
  useEffect(() => {
    const initialState: GeneralAnswers = {
      name: csTask?.type ?? "cs",
      answers: filteredQuestions.map((question) => {
        return {
          id: question.id,
          parent: question.parent,
          creation: new Date(),
          answer: "",
        }
      }),
      time: "",
    }

    if (filteredQuestions.length > 0) {
      // TODO: check if this is correct validation
      if (currentRepetition == 1) {
        setAnswersForm([...patient.questionsAnswered, ...[initialState]])
      } else {
        const answerscs = patient.questionsAnswered.filter((questionnaire) => questionnaire.name === csTask?.type)
        const answersNoncs = patient.questionsAnswered.filter((questionnaire) => questionnaire.name != csTask?.type)

        const updatedAnswerscs = answerscs.flatMap((generalAnswers) => {
          return {
            name: generalAnswers.name,
            answers: [...generalAnswers.answers, ...initialState.answers],
            time: generalAnswers.time,
          }
        })

        // TODO: check if this is correct validation
        if (filteredQuestions.length > 0) {
          setAnswersForm([...answersNoncs, ...updatedAnswerscs])
        }
      }
    }

    if (patient) {
      const remindingSteps = (csTask?.repetitions || 0) - (currentRepetition || 0)
      setCurrentProgress((1 - (currentRepetition - 1 / (csTask?.repetitions || 1))) * 100)
      //      setCurrentProgress((1 - remindingSteps / filteredRecordingTasks.length) * 100)
    }
  }, [filteredQuestions])

  useEffect(() => {
    const requiredQuestions = filteredQuestions.filter((question) => question.required).map((question) => question.id)
    const answersCompleted = answersForm.find((answers) => answers.name === csTask?.type)

    if (
      answersCompleted &&
      answersCompleted.answers.filter((answer) => requiredQuestions.includes(answer.id)).every((answer) => answer.answer !== "")
    ) {
      setQuestionnaireCompleted(true)
    }
  }, [filteredQuestions, answersForm])

  const saveAndGoNextStep = () => {
    // Hack: as slider questions of type 'categoricalLong' are not obligatory, it might occur that a user does not move the slider
    // as he agrees with the default value. In this case we have to manually set the value of the question to its default value
    const currentQuestionsOfTypeCategoricalLong = filteredQuestions
      .filter((questionnaire) => questionnaire.parent === csTask?.type)
      .flatMap((a) => a)
      .filter((a) => a.type === "categoricalLong")
    //.map((a) => a.id)

    // update answersForm with "0" when they are empty ""
    const updatedAnswersForm = answersForm.map((answers) =>
      answers.answers[0].parent === csTask?.type
        ? {
            ...answers,
            answers: answers.answers.map((answer) =>
              currentQuestionsOfTypeCategoricalLong.map((a) => a.id).includes(answer.id)
                ? {
                    ...answer,
                    answer:
                      answer.answer === ""
                        ? currentQuestionsOfTypeCategoricalLong
                            .filter((a: MedicalQuestion) => a.id === answer.id)
                            .map((a: MedicalQuestion) => {
                              // should always go into the if
                              if (a.type === "categoricalLong") {
                                const questionValues = a.value.map((v: { value: any }) => v.value).map(Number)
                                const questionValueSum = questionValues.reduce((sum, current) => sum + current, 0)
                                //return String(Math.min(...a.value.map((v: { value: any }) => v.value).map(Number)))
                                return String(questionValueSum / questionValues.length)
                                //return String(Math.min(...a.value.map((v: { value: any }) => v.value).map(Number)))
                              } else {
                                console.log("WARNING: should never enter here. a:", a)
                                console.log("WARNING: answer:", answer)
                                return "0"
                              }
                            })[0]
                        : answer.answer,
                  }
                : answer
            ),
          }
        : answers
    )

    // check if the form is admin for all the answers for that particular parent
    const answersCompleted = updatedAnswersForm.find((answers) => answers.name === csTask?.type)
    const requiredQuestions = filteredQuestions.filter((question) => question.required).map((question) => question.id)

    if (
      answersCompleted &&
      answersCompleted.answers.filter((answer) => requiredQuestions.includes(answer.id)).every((answer) => answer.answer !== "")
    ) {
      handleResetTimeUser()
      handleStartTimeUser()

      Object.assign(answersCompleted, {
        ...answersCompleted,
        time: timeUser,
      })

      const answersFormWithTime = updatedAnswersForm.map((answer) => (answer.name === answersCompleted.name ? answersCompleted : answer))

      // get all questionnaire parent names to check if all questionnaires have been answered

      savePatientLocally({
        ...patient,
        questionsAnswered: [...answersFormWithTime],
      })

      const update = {
        project_id: patient.projectId,
        questionnaire_id: `${patient.questionnaireId}`,
        questions_answered: JSON.stringify(answersFormWithTime),
        questionnaires_completed: patient.questionnairesCompleted,
      }

      updateQuestionnaire(update)
    }
  }

  if (!project) {
    return <></>
  } else {
    return (
      <Page title={`${project.projectName}`}>
        <Container>
          <ContentStyle sx={{ textAlign: "center" }}>
            {/*<Box sx={{ width: "100%", height: 2 }}>*/}
            {/*  <VoiceProgress completed={currentProgress} />*/}
            {/*</Box>*/}
            <Box sx={{ mt: 3, mb: 2, pl: 2, pr: 2 }}>
              <Typography variant="h3" sx={{ textAlign: "center" }}>
                {translate("mh6.cs.title") + ` (${currentRepetition}/${csTask?.repetitions})`}
              </Typography>
            </Box>
            <Box id="scrollingQuestionBox" sx={{ pt: 3, height: 510, overflowY: "auto" }}>
              {answersForm.length > 0 &&
                filteredQuestions.length > 0 &&
                filteredQuestions.map((question) => (
                  <RenderMedicalQuestion
                    key={question.id}
                    question={question}
                    answersForm={answersForm}
                    setAnswersForm={setAnswersForm}
                    currentQuestionnaireID={csTask?.type ?? "cs"}
                  />
                ))}
            </Box>
            <Box sx={{ paddingTop: 2, paddingBottom: 2 }} display="flex" justifyContent="center" alignContent="center" alignItems="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: "90%", height: 80, fontSize: 30 }}
                onClick={saveAndGoNextStep}
                disabled={!questionnaireCompleted}
              >
                {translate("project.recording.instructions.continue")}
              </Button>
            </Box>
          </ContentStyle>
        </Container>
      </Page>
    )
  }
}

export default CulturalStoryQuestions
