import useLocales from "../../hooks/useLocales"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import usePatient from "../../hooks/usePatient"
import projects from "../../@projects"
import { Box, Container, Typography } from "@mui/material"
import Page from "../../components/Page"
import { styled } from "@mui/material/styles"
import React, { useEffect, useState } from "react"
import { TaskRecording } from "../../@types/taskRecording"
import { Project } from "../../@types/project"
import { useAudioRecorder } from "../../hooks/useAudioRecorder"
import { RECORD_STATUS } from "../../@types/recordingStatus"
import { MixedQuestionRecordingStructure } from "../../@types/mixedQuestionRecording"
import RecordingText from "../../sections/recording/RecordingText"
import AudioRecorder from "../../components/AudioRecorder"
import { CustomStateLocationCountRepetitionsProps } from "../../@types/patient"

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 880,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(1, 0),
}))

const FutureProjectionRecording = () => {
  const { translate, currentLang } = useLocales()
  const navigate = useNavigate()
  const { id } = useParams()
  const { patient } = usePatient()
  const { status } = useAudioRecorder()
  const [filteredRecordingTasks, setFilteredRecordingTasks] = useState<TaskRecording[]>([])
  const [currentTask, setCurrentTask] = useState<TaskRecording>()

  const [project, setProject] = useState<Project>()
  const [currentProgress, setCurrentProgress] = useState(0)
  const [fpTask, setfpTask] = useState<MixedQuestionRecordingStructure>()
  const custom_grey = "#C5C5C5"
  const custom_black = "#000000"
  const [taskColorBackground, setTaskColorBackground] = useState(custom_grey)
  // Cheat: cast type to CustomStateLocationProps to access state parameter
  const { state } = useLocation() as CustomStateLocationCountRepetitionsProps
  const { currentRepetition } = state

  useEffect(() => {
    if (status === RECORD_STATUS.RECORDING) {
      setTaskColorBackground(custom_black)
    } else {
      setTaskColorBackground(custom_black) // custom_grey
    }
  })

  useEffect(() => {
    setProject(projects.find((project) => project.projectID === id))
  }, [id])

  // We filter out all the tasks by the language selected, and we store them in filteredRecordingTasks
  useEffect(() => {
    if (project && project.futureProjection) {
      const fpTask = project.futureProjection?.filter((task) => task.type === "fp")[0]
      setfpTask(fpTask)
      const filteredRecordingTasksByLang = project.futureProjection
        ?.filter((task) => task.type === "fp")
        .flatMap((task) => task.task.recordings)
        .filter((recordingTask) => recordingTask.lang === currentLang.value)
      setFilteredRecordingTasks(filteredRecordingTasksByLang)
    }
  }, [currentLang, project])

  // We select the current task that needs to be done, filtering out the tasks already one
  useEffect(() => {
    //const currTasks = filteredRecordingTasks?.filter((task) => !patient.voiceRecorded.map((voice) => voice.id).includes(task.id))
    const currTasks = filteredRecordingTasks

    if (currTasks && currTasks.length > 0) {
      const taskChangeId = currTasks[0]

      // TODO: fix hardcoded id of voice fp task
      taskChangeId["id"] = "fp_q1" + `_${currentRepetition}`
      setCurrentTask(taskChangeId)
    }

    const isRecordingForCurrentRepetitionAnswered =
      patient.voiceRecorded.filter((questionnaires) => questionnaires.id.startsWith(fpTask?.type || "fp")).length == currentRepetition

    if (filteredRecordingTasks.length > 0 && isRecordingForCurrentRepetitionAnswered) {
      navigate(location.pathname.replace("futureprojectionrecording", `futureprojectionquestions`), {
        state: { currentRepetition: currentRepetition },
      })

      // if (patient.questionnairesCompleted) {
      //   navigate(location.pathname.replace("recording", `final/${patient.patientId}`))
      // } else {
      //   // if questionnaires not completed yet, do them
      //   navigate(location.pathname.replace("recording", `done`))
      // }
    }
  }, [patient, filteredRecordingTasks])

  useEffect(() => {
    if (filteredRecordingTasks && patient) {
      const remindingSteps = filteredRecordingTasks.length - (patient?.voiceRecorded.length || 0)
      const progress = 1 - (currentRepetition - 1 / (fpTask?.repetitions || 1))
      setCurrentProgress((1 - (currentRepetition - 1 / (fpTask?.repetitions || 1))) * 100)
    }
  }, [filteredRecordingTasks, patient])

  function adaptDescription(description: string, lang: string): string {
    let newDesc = description
    switch (currentRepetition) {
      case 1:
        break
      case 2:
        if (lang == "fr") {
          newDesc = description.replace("1er", "2ème")
        } else {
          newDesc = description.replace("1st", "2nd")
        }
        break
      case 3:
        if (lang == "fr") {
          newDesc = description.replace("1er", "3ème")
        } else {
          newDesc = description.replace("1st", "3rd")
        }
        break
      default:
        break
    }
    return newDesc
  }

  if (!project) {
    return <></>
  } else {
    return (
      <Page title={`${project.projectName}`}>
        <Container>
          <ContentStyle sx={{ textAlign: "center" }}>
            {/*<Box sx={{ width: "100%", height: 2 }}>*/}
            {/*  <VoiceProgress completed={currentProgress} />*/}
            {/*</Box>*/}
            <Box sx={{ mt: 3, mb: 2, pl: 2, pr: 2 }}>
              <Typography variant="h3" sx={{ textAlign: "center" }}>
                {translate("mh6.fp.title") + ` (${currentRepetition}/${fpTask?.repetitions})`}
              </Typography>
            </Box>
            {/*{currentTask && <RecordingTask task={currentTask} text_color_background={taskColorBackground} />}*/}
            {currentTask && (
              <Box sx={{ pb: 2, mt: 3 }}>
                <Box sx={{ height: 80, overflowY: "auto" }}>
                  <Box sx={{ pb: 3 }}>
                    <Typography variant="subtitle1" style={{ whiteSpace: "pre-line" }} align="center">
                      {adaptDescription(currentTask.description, currentTask.lang)}{" "}
                    </Typography>
                  </Box>
                  <RecordingText text={currentTask.media} color={taskColorBackground} />
                </Box>
                <AudioRecorder task={currentTask} />
              </Box>
            )}
          </ContentStyle>
        </Container>
      </Page>
    )
  }
}

export default FutureProjectionRecording
