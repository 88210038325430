import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "Not at all",
    value: "not-at-all",
  },
  {
    label: "A little bit",
    value: "a-little-bit",
  },
  {
    label: "Moderately",
    value: "moderately",
  },
  {
    label: "Quite a bit",
    value: "quite-a-bit",
  },
  {
    label: "Extremely",
    value: "extremely",
  },
]

const pclEN: MedicalQuestion[] = [
  {
    id: "pcl_intro",
    name: "intro",
    value:
      "Below is a list of problems that people sometimes have in response to a very stressful experience. Please read " +
      "each problem carefully and then circle one of the numbers to the right to indicate how much you have been " +
      "bothered by that problem in the past month.",
    type: "intro",
    lang: "en",
    parent: "pcl",
    required: false,
  },
  {
    id: "pcl_intro_2",
    name: "intro",
    description: "In the past month, how much were you bothered by:",
    type: "headerText",
    headerStyle: "subtitle1",
    lang: "en",
    order: 1,
    parent: "pcl",
    required: false,
  },
  {
    id: "pcl_1",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Repeated, disturbing, and unwanted memories of a stressful experience?",
    parent: "pcl",
    order: 2,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_2",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Repeated, disturbing dreams of a stressful experience?",
    parent: "pcl",
    order: 3,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_3",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description:
      "Suddenly feeling or acting as if a stressful experience were actually happening again (as if you " +
      "were actually back there reliving it)?",
    parent: "pcl",
    order: 4,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_4",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Feeling very upset when something reminded you of a stressful experience?",
    parent: "pcl",
    order: 5,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_5",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description:
      "Having strong physical reactions when something reminded you of a stressful experience (for " +
      "example, heart pounding, trouble breathing, sweating)?",
    parent: "pcl",
    order: 6,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_6",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Avoiding memories, thoughts, or feelings related to a stressful experience?",
    parent: "pcl",
    order: 7,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_7",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description:
      "Avoiding external reminders of a stressful experience (for example, people, places, conversations, activities," +
      " objects, or situations)?",
    parent: "pcl",
    order: 8,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_8",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Trouble remembering important parts of a stressful experience?",
    parent: "pcl",
    order: 9,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_9",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description:
      "Having strong negative beliefs about yourself, other people, or the world (for example, having " +
      "thoughts such as: I am bad, there is something seriously wrong with me, no one can be trusted, the world is" +
      " completely dangerous)?",
    parent: "pcl",
    order: 10,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_10",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Blaming yourself or someone else for a stressful experience or what happened after it?",
    parent: "pcl",
    order: 11,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_11",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Having strong negative feelings such as fear, horror, anger, guilt, or shame?",
    parent: "pcl",
    order: 12,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_12",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Loss of interest in activities that you used to enjoy?",
    parent: "pcl",
    order: 13,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_13",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Feeling distant or cut off from other people?",
    parent: "pcl",
    order: 14,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_14",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description:
      "Trouble experiencing positive feelings (for example, being unable to feel happiness or have loving" +
      " feelings for people close to you)?",
    parent: "pcl",
    order: 15,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_15",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Irritable behavior, angry outbursts, or acting aggressively?",
    parent: "pcl",
    order: 16,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_16",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Taking too many risks or doing things that could cause you harm?",
    parent: "pcl",
    order: 17,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_17",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Being “superalert” or watchful or on guard?",
    parent: "pcl",
    order: 18,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_18",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Feeling jumpy or easily startled?",
    parent: "pcl",
    order: 19,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_19",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Having difficulty concentrating?",
    parent: "pcl",
    order: 20,
    lang: "en",
    required: true,
  },
  {
    id: "pcl_20",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Trouble falling or staying asleep?",
    parent: "pcl",
    order: 21,
    lang: "en",
    required: true,
  },
]

export default pclEN
