import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
]

const fssEN: MedicalQuestion[] = [
  {
    id: "fss_intro",
    name: "intro",
    description:
      "The following questionnaire is a method of evaluating the impact of fatigue on you. It is a short questionnaire " +
      "that requires you to rate your level of fatigue. The questionnaire contains nine statements that rate the severity " +
      "of your fatigue symptoms. Read each statement and circle a number from 1 to 7, based on how accurately it reflects" +
      " your condition during the past week and the extent to which you agree or disagree that the statement applies to you.",
    type: "headerText",
    headerStyle: "subtitle1",
    order: 1,
    lang: "en",
    parent: "fss",
    required: false,
  },
  {
    id: "fss_intro2",
    name: "intro",
    description:
      "A low value (e.g., 1) indicates strong disagreement with the statement, whereas a high value (e.g., 7) indicates" +
      " strong agreement.",
    type: "headerText",
    headerStyle: "subtitle1",
    order: 2,
    lang: "en",
    parent: "fss",
    required: false,
  },
  {
    id: "fss_1",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "My motivation is lower when I am fatigued.",
    parent: "fss",
    order: 3,
    lang: "en",
    required: false,
  },
  {
    id: "fss_2",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "Exercise brings on my fatigue",
    parent: "fss",
    order: 4,
    lang: "en",
    required: false,
  },
  {
    id: "fss_3",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "I am easily fatigued",
    parent: "fss",
    order: 5,
    lang: "en",
    required: false,
  },
  {
    id: "fss_4",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "Fatigue interferes with my physical functioning",
    parent: "fss",
    order: 6,
    lang: "en",
    required: false,
  },
  {
    id: "fss_5",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "Fatigue causes frequent problems for me",
    parent: "fss",
    order: 7,
    lang: "en",
    required: false,
  },
  {
    id: "fss_6",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "My fatigue prevents sustained physical functioning",
    parent: "fss",
    order: 8,
    lang: "en",
    required: false,
  },
  {
    id: "fss_7",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "Fatigue interferes with carrying out certain duties and responsibilities",
    parent: "fss",
    order: 9,
    lang: "en",
    required: false,
  },
  {
    id: "fss_8",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "Fatigue is among my three most disabling symptoms",
    parent: "fss",
    order: 10,
    lang: "en",
    required: false,
  },
  {
    id: "fss_9",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "Fatigue interferes with my work, family, or social life.",
    parent: "fss",
    order: 11,
    lang: "en",
    required: false,
  },
]
export default fssEN
