import { MixedQuestionRecording } from "../../../../@types/mixedQuestionRecording"

const culturalStoryFR: MixedQuestionRecording = {
  questionnaires: [
    {
      id: "cs_q1",
      name: "Title of event",
      type: "freeText",
      description: "Titre de l'évènement",
      parent: "cs",
      order: 2,
      lang: "fr",
      required: true,
    },
    {
      id: "cs_q2",
      name: "Common event",
      type: "number",
      minValue: 1,
      maxValue: 100,
      description: "Dans quelle mesure l'événement est-il courant ? ",
      info: "Sur 100 personnes, combien vivront cet événement au moins une fois au cours de leur vie ?",
      parent: "cs",
      order: 3,
      lang: "fr",
      required: true,
    },
    {
      id: "cs_q3",
      name: "Importance of event",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
      ],
      labelStart: "Sans importance",
      labelEnd: "De la plus grande importance",
      description: "Quelle est l'importance de l'événement dans la vie de la personne ?",
      parent: "cs",
      order: 4,
      lang: "fr",
      required: false,
    },
    {
      id: "cs_q4",
      name: "Common event",
      type: "number",
      minValue: 1,
      maxValue: 100,
      description: "A quel âge l'événement est-il censé avoir lieu ?",
      parent: "cs",
      order: 5,
      lang: "fr",
      required: true,
    },
    {
      id: "cs_q5",
      name: "Importance of event",
      type: "categoricalLong",
      value: [
        { label: "-3", value: "-3" },
        { label: "-2", value: "-2" },
        { label: "-1", value: "-1" },
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
      ],
      labelStart: "Très négatif",
      labelEnd: "Très positif",
      description: "L'événement est-il émotionnellement positif ou négatif ?",
      parent: "cs",
      order: 6,
      lang: "fr",
      required: false,
    },
  ],
  recordings: [],
}

export default culturalStoryFR
