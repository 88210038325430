import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "Not at all",
    value: "not-at-all",
  },
  {
    label: "Several days",
    value: "several-days",
  },
  {
    label: "More than half the days",
    value: "more-than-half-the-days",
  },
  {
    label: "Nearly every day",
    value: "nearly-every-day",
  },
]

const phqEN: MedicalQuestion[] = [
  {
    id: "phq_intro",
    name: "intro",
    value: "The following questions aim to collect information about your mood over the past two weeks.",
    type: "intro",
    lang: "en",
    parent: "phq",
    required: false,
  },
  {
    id: "phq_intro2",
    name: "intro",
    description: "Over the last two weeks, how often have you been bothered by any of the following problems?",
    type: "headerText",
    headerStyle: "subtitle1",
    order: 1,
    lang: "en",
    parent: "phq",
    required: false,
  },
  {
    id: "phq_1",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Little interest or pleasure in doing things?",
    parent: "phq",
    order: 2,
    lang: "en",
    required: true,
  },
  {
    id: "phq_2",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Feeling down, depressed, or hopeless?",
    parent: "phq",
    order: 3,
    lang: "en",
    required: true,
  },
  {
    id: "phq_3",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Trouble falling or staying asleep, or sleeping too much?",
    parent: "phq",
    order: 4,
    lang: "en",
    required: true,
  },
  {
    id: "phq_4",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Feeling tired or having little energy?",
    parent: "phq",
    order: 5,
    lang: "en",
    required: true,
  },
  {
    id: "phq_5",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Poor appetite or overeating?",
    parent: "phq",
    order: 6,
    lang: "en",
    required: true,
  },
  {
    id: "phq_6",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Feeling bad about yourself - or that you are a failure or have let yourself or your family down?",
    parent: "phq",
    order: 7,
    lang: "en",
    required: true,
  },
  {
    id: "phq_7",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Trouble concentrating on things, such as reading the newspaper or watching television?",
    parent: "phq",
    order: 8,
    lang: "en",
    required: true,
  },
  {
    id: "phq_8",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description:
      "Moving or speaking so slowly that other people could have noticed? Or the opposite - being so fidgety or " +
      "restless that you have been moving around a lot more than usual?",
    parent: "phq",
    order: 9,
    lang: "en",
    required: true,
  },
  {
    id: "phq_9",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Thoughts that you would be better off dead, or of hurting yourself in some way?",
    parent: "phq",
    order: 10,
    lang: "en",
    required: true,
  },
]

export default phqEN
