import { MedicalQuestionCategoricalLong } from "../../@types/medicalQuestion"
import { GeneralAnswers } from "../../@types/patient"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Box, FormControl, Grid, Paper, Slider, Typography } from "@mui/material"

type RenderMedicalQuestionCategoricalProps = {
  question: MedicalQuestionCategoricalLong
  answersForm: GeneralAnswers[]
  setAnswersForm: Dispatch<SetStateAction<GeneralAnswers[]>>
  currentQuestionnaireID: string
}

const RenderMedicalQuestionCategoricalLong = ({
  question,
  answersForm,
  setAnswersForm,
  currentQuestionnaireID,
}: RenderMedicalQuestionCategoricalProps) => {
  // We select the first answer as valid because the slider must

  const [sliderDisabled, setSliderDisabled] = useState(false) // initalise with false to have it disabled

  useEffect(() => {
    if (!sliderDisabled) {
      //handleAnswerChange(question.value[0].value)
      handleAnswerChange(question.value[(Math.round(question.value.length / 2) as number) - 1].value.toString())
    }
  }, [sliderDisabled])

  const handleAnswerChange = (event: string) => {
    setAnswersForm((answers: GeneralAnswers[]) => {
      const result: GeneralAnswers[] = answers.map((answer) => {
        if (answer.name === currentQuestionnaireID) {
          const updatedAnswers = answer.answers.map((answer) => {
            if (answer.id === question.id) {
              return { ...answer, answer: event }
              //return { ...answer, answer: event }
            } else {
              return answer
            }
          })

          return { name: currentQuestionnaireID, answers: updatedAnswers, time: "" }
        } else {
          return answer
        }
      })
      return result
    })
  }

  const getSliderText = question.value.map((cat, index) => ({ value: index, label: cat.label }))

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    handleAnswerChange(question.value[newValue as number].value)
  }

  // if no description text is defined for the current question, use the whole width of space to show slider
  if (question.description == undefined) {
    return (
      <FormControl fullWidth sx={{ pt: 1, pb: 1 }}>
        <Paper elevation={3} onClick={() => setSliderDisabled(false)}>
          {/*<Paper elevation={3}>*/}
          <Grid container spacing={0} sx={{ width: "100%", pt: 1, pb: 1 }}>
            <Grid item xs={12} md={12} display="flex" justifyContent="center" alignContent="center" alignItems="center">
              <Box width="25%" sx={{ pr: 2 }}>
                <Typography sx={{ fontSize: 11 }}>{question.labelStart}</Typography>
              </Box>
              <Box width="45%">
                <Slider
                  aria-label="Level"
                  defaultValue={Math.round((question.value.length - 1) / 2)}
                  marks={getSliderText}
                  valueLabelDisplay="off"
                  step={1}
                  min={0}
                  max={question.value.length - 1}
                  onChange={handleSliderChange}
                  disabled={sliderDisabled}
                  sx={{
                    "& .MuiSlider-thumb": {
                      width: "19px",
                      height: "19px",
                    },
                  }}
                />
              </Box>
              <Box width="25%" sx={{ pl: 2 }}>
                <Typography sx={{ fontSize: 12 }}>{question.labelEnd}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </FormControl>
    )
  } else {
    return (
      <FormControl fullWidth sx={{ pt: 1, pb: 1 }}>
        <Paper elevation={3} onClick={() => setSliderDisabled(false)}>
          {/*<Paper elevation={3}>*/}
          <Grid container spacing={0} sx={{ width: "100%", pt: 1, pb: 1 }}>
            <Grid item xs={12} md={6} display="flex" justifyContent="center" alignContent="center" alignItems="center">
              <Box width="80%">
                <Typography textAlign="center">{question.description}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} display="flex" justifyContent="center" alignContent="center" alignItems="center">
              <Box width="20%" sx={{ pr: 2 }}>
                <Typography sx={{ fontSize: 11 }}>{question.labelStart}</Typography>
              </Box>
              <Box width="55%">
                <Slider
                  aria-label="Level"
                  defaultValue={Math.round((question.value.length - 1) / 2)}
                  marks={getSliderText}
                  valueLabelDisplay="off"
                  step={1}
                  min={0}
                  max={question.value.length - 1}
                  onChange={handleSliderChange}
                  disabled={sliderDisabled}
                  sx={{
                    "& .MuiSlider-thumb": {
                      width: "19px",
                      height: "19px",
                    },
                  }}
                />
              </Box>
              <Box width="20%" sx={{ pl: 2 }}>
                <Typography sx={{ fontSize: 12 }}>{question.labelEnd}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </FormControl>
    )
  }
}

export default RenderMedicalQuestionCategoricalLong
