import { MedicalQuestion } from "../../../../@types/medicalQuestion"

const generalEN: MedicalQuestion[] = [
  {
    id: "title_socio_demo",
    name: "Title sociodemographic questions",
    description: "Sociodemographic data",
    type: "headerText",
    headerStyle: "h3",
    lang: "en",
    parent: "general_info",
    order: 1,
    required: false,
  },
  {
    id: "user_id",
    name: "User ID",
    type: "freeText",
    description: "Please create your original ID which consists of your birthday followed by the last 2 digits of your phone number.",
    info:
      "For example: if I was born on January 5th and my number is 0687574838 my ID is 0538) " +
      "*Please remember this number as you will use it several times during the study.",
    parent: "general_info",
    order: 2,
    lang: "en",
    required: true,
    validateRegex: "^[0-9]*$",
  },
  {
    id: "age_minor",
    name: "Age Minor",
    type: "categoricalShort",
    value: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    description: "Are you 18 or older?",
    parent: "general_info",
    order: 3,
    lang: "en",
    required: true,
  },
  {
    id: "age",
    name: "Age",
    type: "number",
    minValue: 18,
    maxValue: 100,
    description: "How old are you?",
    parent: "general_info",
    order: 4,
    lang: "en",
    required: false,
    dependsOn: {
      id: "age_minor",
      values: ["yes"],
    },
  },
  {
    id: "educ",
    name: "Education level",
    type: "number",
    minValue: 1,
    maxValue: 25,
    description: "How many years of education have you completed?",
    info:
      "Please count each year since the first grade. Example: if you stopped your studies after high school, the " +
      "number of years is 12.",
    parent: "general_info",
    order: 5,
    lang: "en",
    required: true,
  },
  {
    id: "status_prof",
    name: "Professional status",
    type: "categoricalShort",
    value: [
      { label: "Employed", value: "employed" },
      { label: "Student", value: "student" },
      { label: "Retired", value: "retired" },
      { label: "Unemployed", value: "unemployed" },
      { label: "Not employed", value: "not-employed" },
    ],
    description: "What is your professional status?",
    parent: "general_info",
    order: 6,
    lang: "en",
    required: true,
  },
  {
    id: "job",
    name: "Job",
    type: "freeText",
    description: "What is your profession?",
    parent: "general_info",
    order: 7,
    lang: "en",
    required: true,
  },
  {
    id: "citizenship_lib",
    name: "Citizenshipt libanese",
    type: "categoricalShort",
    value: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    description: "Are you a Lebanese citizen?",
    parent: "general_info",
    order: 8,
    lang: "en",
    required: true,
  },
  {
    id: "citizenship_other",
    name: "Citizenship",
    type: "freeText",
    description: "If not, what citizenship do you have?",
    parent: "general_info",
    order: 9,
    lang: "en",
    required: false,
    dependsOn: {
      id: "citizenship_lib",
      values: ["no"],
    },
  },
  {
    id: "residence_lib",
    name: "Residence libanese",
    type: "categoricalShort",
    value: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    description: "Is Libanon your current place of residence?",
    parent: "general_info",
    order: 10,
    lang: "en",
    required: true,
  },
  {
    id: "residence_other",
    name: "Other residence",
    type: "freeText",
    description: "What is your current place of residence?",
    parent: "general_info",
    order: 11,
    lang: "en",
    required: false,
    dependsOn: {
      id: "residence_lib",
      values: ["no"],
    },
  },
  {
    id: "visit_lib",
    name: "Visits Libanon",
    type: "categoricalShort",
    value: [
      { label: "Frequently (>1x year)", value: "frequently-more-than-once-a-year" },
      { label: "Occasionally", value: "occasionally" },
      { label: "Infrequently (1x 5 years)", value: "infrequently-once-every-five-years" },
      { label: "No more visits", value: "no-more-visits" },
      { label: "No answer", value: "no-answer" },
      { label: "Other", value: "other" },
    ],
    description: "How often do you visit Lebanon (on average)?",
    parent: "general_info",
    order: 12,
    lang: "en",
    required: false,
    dependsOn: {
      id: "residence_lib",
      values: ["no"],
    },
  },
  {
    id: "visit_lib_other",
    name: "Visit Libanon other",
    type: "freeText",
    description: "If other, specify",
    parent: "general_info",
    order: 13,
    lang: "en",
    required: false,
    dependsOn: {
      id: "visit_lib",
      values: ["other"],
    },
  },
  {
    id: "diag_psy",
    name: "Diagnosed psychiatric disorder",
    type: "multi",
    value: [
      { label: "No", value: "no" },
      { label: "Depression", value: "depression" },
      { label: "Bipolar disorder", value: "bipolar-disorder" },
      { label: "Anxiety disorder", value: "anxiety-disorder" },
      { label: "Schizophrenia or psychotic disorders", value: "schiziphrenia-or-psychotic-disorders" },
      { label: "Eating disorder", value: "eating-disorder" },
      { label: "Substance abuse or addiction", value: "substance-abuse-addiction" },
      { label: "Other (Free text)", value: "other" },
    ],
    description: "Do you have a diagnosed psychiatric disorder?",
    info: "check all that apply",
    parent: "general_info",
    order: 14,
    lang: "en",
    required: false,
  },
  {
    id: "diag_psy_other",
    name: "Diagnosed psychiatric disorder other",
    type: "freeText",
    description: "If other, specify",
    parent: "general_info",
    order: 15,
    lang: "en",
    required: false,
    dependsOn: {
      id: "diag_psy",
      values: ["other"],
    },
  },
  {
    id: "consult_psy",
    name: "Consultation psy",
    type: "categoricalShort",
    value: [
      { label: "No", value: "no" },
      { label: "Yes, but I don't consult anymore", value: "yes-but-i-dont-consult-anymore" },
      { label: "Yes, and I am still consulting ", value: "yes-and-still-consulting" },
    ],
    description: "Have you ever consulted a psychologist or psychiatrist?",
    parent: "general_info",
    order: 16,
    lang: "en",
    required: true,
  },
  {
    id: "lang",
    name: "Language",
    type: "categoricalShort",
    value: [
      { label: "Arabic", value: "arabic" },
      { label: "French", value: "french" },
      { label: "English", value: "english" },
      { label: "Other", value: "other" },
    ],
    description: "Native language",
    parent: "general_info",
    order: 17,
    lang: "en",
    required: true,
  },
  {
    id: "lang_other",
    name: "Language other",
    type: "freeText",
    description: "If other, specify",
    parent: "general_info",
    order: 18,
    lang: "en",
    required: false,
    dependsOn: {
      id: "lang",
      values: ["other"],
    },
  },
  {
    id: "sex",
    name: "Gender",
    type: "categoricalShort",
    value: [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" },
      { label: "Other", value: "other" },
    ],
    description: "Gender",
    parent: "general_info",
    order: 19,
    lang: "en",
    required: true,
  },
  {
    id: "sex_other",
    name: "Sex other",
    type: "freeText",
    description: "If other, specify",
    parent: "general_info",
    order: 20,
    lang: "en",
    required: false,
    dependsOn: {
      id: "sex",
      values: ["other"],
    },
  },
  {
    id: "diag_resp",
    name: "Diagnosis respiratory disease",
    type: "categoricalShort",
    value: [
      { label: "COPD (Chronic Obstructive Pulmonary Disease)", value: "copd" },
      { label: "Asthma", value: "asthma" },
      { label: "COVID-19 in the last 3 months", value: "covid" },
      { label: "Other respiratory infections in the last 3 months", value: "other-respiratory-infection" },
      { label: "Respiratory allergies", value: "respiratory-allergies" },
      { label: "Pulmonary fibrosis", value: "pulmonary-fibosis" },
      { label: "No", value: "no" },
    ],
    description: "Do you have a diagnosed respiratory condition?",
    info: "Check all that apply",
    parent: "general_info",
    order: 21,
    lang: "en",
    required: true,
  },
  {
    id: "l_cov19",
    name: "Long covid-19",
    type: "categoricalShort",
    value: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    description: "Do you have persisting Covid-19 symptoms (Long-Covid)?",
    parent: "general_info",
    order: 22,
    lang: "en",
    required: false,
    dependsOn: {
      id: "diag_resp",
      values: ["covid"],
    },
  },
  {
    id: "preg",
    name: "Pregnancy to date",
    type: "categoricalShort",
    value: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
    ],
    description: "Are you pregnant?",
    parent: "general_info",
    order: 23,
    lang: "en",
    required: false,
  },
  {
    id: "preg_d",
    name: "pregnancy month",
    type: "number",
    minValue: 1,
    maxValue: 9,
    description: "What month of pregnancy are you in?",
    parent: "general_info",
    order: 24,
    lang: "en",
    dependsOn: {
      id: "preg",
      values: ["yes"],
    },
    required: false,
  },
]

export default generalEN
