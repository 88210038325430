import { TaskRecording } from "../../../@types/taskRecording"

const taskRecordingsEN: TaskRecording[] = [
  {
    id: "read_speech_1",
    name: "Reading task Human Rights",
    type: "text",
    description:
      "1. Click the green button to start the recording.\n" +
      "2. Please read the following text aloud.\n" +
      "3. Once you are finished, click the button again to stop recording.",
    media:
      "Everyone has the right to a standard of living adequate for the health and well-being of himself and of " +
      "his family, including food, clothing, housing and medical care and necessary social services, and the right to " +
      "security in the event of unemployment, sickness, disability, widowhood, old age or other lack of livelihood in " +
      "circumstances beyond his control.",
    lang: "en",
  },
  {
    id: "sust_vowel",
    name: "Sustained vowel task aaah",
    type: "text",
    media: undefined,
    description:
      "1. Click the green button to start the recording.\n" +
      "2. Say ‘aaaaah’ as long as you can without breathing.\n" +
      "3. Once you are finished, click the button again to stop recording.",
    lang: "en",
  },
  {
    id: "fs_how_are_you",
    name: "Free speech answer a question",
    type: "text",
    media: "How are you today?",
    description:
      "1. Click the green button to start the recording.\n" +
      "2. Answer the following question by speaking at least 20 seconds. \n" +
      "3. Once you are finished, click the button again to stop recording.",
    lang: "en",
  },
]

export default taskRecordingsEN
