import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
]

const fssFR: MedicalQuestion[] = [
  {
    id: "fss_intro2",
    name: "intro",
    description:
      "Le questionnaire suivant vise à évaluer l'impact de la fatigue sur vous. C'est un questionnaire qui est court et " +
      "qui nécessite que vous évaluiez votre niveau de fatigue. Le questionnaire comporte 9 items qui évaluent la sévérité " +
      "de vos symptômes de fatigue. Merci de lire chaque item et de cocher une réponse entre 1 et 7 qui représente le " +
      "mieux votre situation actuelle, au cours de la semaine passée et qui décrit à quel point la description s'applique à vous.",
    type: "headerText",
    headerStyle: "subtitle1",
    order: 1,
    lang: "fr",
    parent: "fss",
    required: false,
  },
  {
    id: "fss_intro2",
    name: "intro",
    description:
      "Un score faible (par exemple : 1) indique un fort désaccord avec la description alors qu'un score plus élevé (par" +
      " exemple : 7) indique un fort accord avec la description",
    type: "headerText",
    headerStyle: "subtitle1",
    order: 2,
    lang: "fr",
    parent: "fss",
    required: false,
  },
  {
    id: "fss_1",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "Je me sens moins motivé du fait de la fatigue",
    parent: "fss",
    order: 3,
    lang: "fr",
    required: false,
  },
  {
    id: "fss_2",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "L'exercice physique est pour moi source de fatigue",
    parent: "fss",
    order: 4,
    lang: "fr",
    required: false,
  },
  {
    id: "fss_3",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "Je suis facilement fatigué(e)",
    parent: "fss",
    order: 5,
    lang: "fr",
    required: false,
  },
  {
    id: "fss_4",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "La fatigue interfère avec mon activité physique",
    parent: "fss",
    order: 6,
    lang: "fr",
    required: false,
  },
  {
    id: "fss_5",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "La fatigue est souvent un problème pour moi",
    parent: "fss",
    order: 7,
    lang: "fr",
    required: false,
  },
  {
    id: "fss_6",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "Ma fatigue m’empêche de réaliser des tâches physiques soutenues et prolongées",
    parent: "fss",
    order: 8,
    lang: "fr",
    required: false,
  },
  {
    id: "fss_7",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "La fatigue interfère avec mes facultés pour la réalisation de certaines activités et responsabilités",
    parent: "fss",
    order: 9,
    lang: "fr",
    required: false,
  },
  {
    id: "fss_8",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "La fatigue fait partie des mes 3 symptômes les plus gênants ",
    parent: "fss",
    order: 10,
    lang: "fr",
    required: false,
  },
  {
    id: "fss_9",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "La fatigue interfère avec mon travail, ma famille ou ma vie sociale",
    parent: "fss",
    order: 11,
    lang: "fr",
    required: false,
  },
]

export default fssFR
